import type { ExtractRouteParams } from 'react-router'

type RouteConfig = {
  path: string
  queryParams?: readonly string[]
  isLegacyRoute: boolean
}

export const routes = {
  FindHome: {
    path: '/find-home',
    queryParams: ['homeTypes', 'searchAreas'],
    isLegacyRoute: false,
  },
  FindVacation: { path: '/find-vacation', isLegacyRoute: true },
  FindTenant: { path: '/find-tenant', isLegacyRoute: true },
  Favorites: { path: '/favorites', isLegacyRoute: true },
  Home: {
    path: '/home/:id',
    queryParams: [
      'showGallery',
      'index',
      'showVirtualTour',
      'viewingOpenedFrom',
      'isPublishHomeDialogOpen',
      'applicationStep',
      'subFlow',
      'claimStep',
      'success',
      'transaction_id',
      'viewingBooking',
    ],
    isLegacyRoute: true,
  },
  InstantSign: {
    path: '/home/:id/instant-sign/:step?',
    isLegacyRoute: true,
  },
  IdVerification: {
    path: '/id-verification/:type?',
    queryParams: ['redirectUrl'],
    isLegacyRoute: true,
  },
  CreateListing: { path: '/create-listing', isLegacyRoute: true },
  EditListing: { path: '/edit-listing/:homeId', isLegacyRoute: true },
  CreateViewings: { path: '/edit-listing/:homeId/create-viewings', isLegacyRoute: true },
  Leases: { path: '/leases', isLegacyRoute: true },
  Invoices: { path: '/leases/:contractId/invoices', queryParams: ['prevRoute', 'id'], isLegacyRoute: true },
  Inbox: { path: '/messages', isLegacyRoute: true },
  Chat: {
    path: '/messages/:conversationId',
    queryParams: ['status', 'initialContractPage', 'lease', 'vacation'],
    isLegacyRoute: true,
  },
  HomeqApplications: {
    path: '/messages/homeq-applications',
    isLegacyRoute: true,
  },
  MyListings: { path: '/my-listings', isLegacyRoute: true },
  Listing: { path: '/my-listings/:homeId', isLegacyRoute: true },
  Profile: { path: '/profile/:id', queryParams: ['homeId'], isLegacyRoute: true },
  Settings: { path: '/settings', queryParams: ['section', 'field'], isLegacyRoute: true },
  Help: { path: '/help', isLegacyRoute: true },
} as const satisfies Record<string, RouteConfig>

export type RouteNamesWithQueryParams = {
  [K in keyof Routes]: Routes[K] extends { queryParams: LegitimateAny } ? K : never
}[keyof Routes]

export type QueryParamsOfRoute<T extends RouteNamesWithQueryParams> = Record<
  Routes[T]['queryParams'][number],
  string | undefined | null | number | boolean
>

export type Routes = typeof routes
export type RouteName = keyof Routes
export type QueryParams<T extends RouteName> = T extends RouteNamesWithQueryParams
  ? Partial<QueryParamsOfRoute<T>>
  : undefined

export type RouteParams<T extends RouteName> = ExtractRouteParams<(typeof routes)[T]['path'], string>

type EmptyObject = {
  [K in LegitimateAny]: never
}
export type UrlParams<T extends string> =
  ExtractRouteParams<T> extends EmptyObject ? undefined : ExtractRouteParams<T, string>
