/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull p2'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/implementation/p2)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'default';

export const ApiKey: Record<Environment, string> = {
  default: 'a72d2ae9c5bbdb14724d78b786d7ad35'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'p2',
    versionId: 'ce1379e7-24a9-42eb-a030-1a00371be076'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  age?: number;
  createListingABTestGroup?: string;
  createListingOnboardingABTestGroup?: string;
  /**
   * Is the user selected to see the new Home view? (September 2023, team Better)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | NEW_HOME_VIEW, OLD_HOME_VIEW |
   */
  homeViewABTestGroup?: "NEW_HOME_VIEW" | "OLD_HOME_VIEW";
  /**
   * True if the user is from Qasa/Schibsted (determined from e-mail)
   */
  internalUser?: boolean;
  landlord?: boolean;
  /**
   * The platform of the users device, i.e. web, ios, android.
   *
   * Amplitudes built in Platform property does not work correctly on native and returns web for all platforms.
   */
  platform?: string;
  tenant?: boolean;
  /**
   * Track if the user has the web socket feature enabled. This means all chat messages will go through web sockets instead of polling.
   */
  useWebsockets?: boolean;
}

export interface AmplitudePageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Domain"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Location"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Path"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page URL"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referrer?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  referring_domain?: string;
}

export interface AboutQasaDialogOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page URL"?: string;
}

export interface ApplicationFlowStepViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | login, completeProfile, creditVerification, idVerification, summary, incomeVerification, tenantPreference, postApplication, initial, applicationMessage, connectHomeQAccount, housingSituation, createApplication, success, bookViewingSlot, subletIdVerification |
   */
  applicationStep:
    | "login"
    | "completeProfile"
    | "creditVerification"
    | "idVerification"
    | "summary"
    | "incomeVerification"
    | "tenantPreference"
    | "postApplication"
    | "initial"
    | "applicationMessage"
    | "connectHomeQAccount"
    | "housingSituation"
    | "createApplication"
    | "success"
    | "bookViewingSlot"
    | "subletIdVerification";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | incomeInitial, incomeCheck, incomeVerified, creditIntro, creditInitial, creditCreating, creditVerified, creditSkip, creditVerifyAgain, tenantIntro, creditDelete, tenantMoveIn |
   */
  applicationStepState?:
    | "incomeInitial"
    | "incomeCheck"
    | "incomeVerified"
    | "creditIntro"
    | "creditInitial"
    | "creditCreating"
    | "creditVerified"
    | "creditSkip"
    | "creditVerifyAgain"
    | "tenantIntro"
    | "creditDelete"
    | "tenantMoveIn";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sublet, external, proPilot, shortcut, instantSign, homeQ |
   */
  contractVariant?: "sublet" | "external" | "proPilot" | "shortcut" | "instantSign" | "homeQ";
  homeId?: string;
  /**
   * For application Flow step viewed, this shows all other queryparams present on the event other than ApplicationStep and subFlowStep
   */
  restQueryParams?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | idVerification, emailVerification, homeQIntroScreen, connectAccount, connectSuccess, tenantIntro, tenantMoveIn, creditIntro, creditInitial, creditCreating, creditVerified, incomeInitial, incomeCheck, incomeVerified |
   */
  subFlowStep?:
    | "idVerification"
    | "emailVerification"
    | "homeQIntroScreen"
    | "connectAccount"
    | "connectSuccess"
    | "tenantIntro"
    | "tenantMoveIn"
    | "creditIntro"
    | "creditInitial"
    | "creditCreating"
    | "creditVerified"
    | "incomeInitial"
    | "incomeCheck"
    | "incomeVerified";
}

export interface ApplicationModalOpenedProperties {
  homeId: string;
  isFirsthandHome?: boolean;
  isShortcutHome?: boolean;
}

export interface ButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page URL"?: string;
  buttonType?: string;
  class?: string;
  /**
   * The `form` attribute of the button. Specifies the form the button belongs to.
   */
  formId?: string;
  id?: string;
  /**
   * Aria label
   */
  label?: string;
  /**
   * Inner html text
   */
  text?: string;
}

export interface ConnectHomeqUserErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | idTaken, emailTaken, unknown, accountAlreadyConnected |
   */
  connectHomeqUserAccountError?: "idTaken" | "emailTaken" | "unknown" | "accountAlreadyConnected";
}

export interface ContactTenantModalOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page URL"?: string;
  landlordUid?: string;
}

export interface ContractPartiallySignedProperties {
  homeId: string;
  /**
   * "tenant" or "landlord"
   */
  party?: string;
}

export interface CreditCheckDeniedProperties {
  isFirsthandHome?: boolean;
  /**
   * boolean denoting whether a home is of contract.signingProcess "instant"
   */
  isInstantSignHome?: boolean;
  isShortcutHome?: boolean;
}

export interface DigitalViewingOpenedProperties {
  homeId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | carousel, button |
   */
  viewingOpenedFrom: "carousel" | "button";
}

export interface HomeqIntroAccountActionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | creatingNewAccount, connectingExistingAccount |
   */
  homeqAccountAction: "creatingNewAccount" | "connectingExistingAccount";
}

export interface HomeqRedirectDialogOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Path"?: string;
}

export interface HomeqRedirectDialogReadMoreClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Path"?: string;
}

export interface HomeqRedirectLinkClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page Path"?: string;
  homeqRedirectPath: string;
  homeqRedirectUrl?: string;
}

export interface InstantMatchContactTenantProperties {
  homeId: string;
  tenantUid: string;
}

export interface InstantMatchTenantListViewedProperties {
  homeId: string;
}

export interface InstantMatchViewTenantProfileProperties {
  homeId: string;
  tenantUid: string;
}

export interface InstantSignAnotherPersonSignedFirstProperties {
  homeId: string;
}

export interface InstantSignFlowStepViewedProperties {
  homeId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | creditCheck, summary, contractPreview, moveInDate, completeProfile |
   */
  instantSignStep: "creditCheck" | "summary" | "contractPreview" | "moveInDate" | "completeProfile";
}

export interface InstantSignInitiateContractSigningProperties {
  homeId: string;
}

export interface InstantSignLandlordStartSigningProperties {
  homeId: string;
}

export interface InstantSignModalOpenedProperties {
  homeId: string;
}

export interface InstantSignNotInterestedProperties {
  homeId: string;
}

export interface InstantSignStartFlowProperties {
  homeId: string;
}

export interface IntentfulSearchProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | false |
   * | Item Type | string |
   */
  homeIds: string[];
  order: string;
  searchAlgorithm: string;
}

export interface LandlordContractSignLandingPageOpenedProperties {
  homeId: string;
}

export interface ListingGalleryImageLoadedProperties {
  homeId: string;
  imageUrl: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  loadTime: number;
}

export interface OikotieOtherRentalPropertyClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page URL"?: string;
}

export interface PublishListingProperties {
  homeId: string;
}

export interface SendApplicationProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  homeId: string;
  isHomeqHome?: boolean;
  isShortcutHome?: boolean;
}

export interface SendChatMessageProperties {
  conversationId: string;
  homeId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  messageLength: number;
  messageType: string;
}

export interface ShortcutOptInProperties {
  homeId: string;
}

export interface ShortcutOptOutProperties {
  homeId: string;
}

export interface SimilarHomeOpenedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page URL"?: string;
  homeId: string;
}

export interface SuggestedDescriptionRequestedProperties {
  homeId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  loadTime: number;
}

export interface SupportCallRequestedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Page URL"?: string;
}

export interface TenantContractSignLandingPageOpenedProperties {
  homeId: string;
}

export interface ViewHomeProperties {
  homeId: string;
  isHomeqHome?: boolean;
  /**
   * boolean denoting whether a home is of contract.signingProcess "instant"
   */
  isInstantSignHome?: boolean;
  isShortcutHome?: boolean;
  isUserLandlord?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  rentalType?: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AmplitudePageViewed implements BaseEvent {
  event_type = '[Amplitude] Page Viewed';

  constructor(
    public event_properties?: AmplitudePageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AboutQasaDialogOpened implements BaseEvent {
  event_type = 'About qasa dialog opened';

  constructor(
    public event_properties?: AboutQasaDialogOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApplicationFlowStepViewed implements BaseEvent {
  event_type = 'Application Flow Step Viewed';

  constructor(
    public event_properties: ApplicationFlowStepViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApplicationModalOpened implements BaseEvent {
  event_type = 'Application modal opened';

  constructor(
    public event_properties: ApplicationModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ButtonClicked implements BaseEvent {
  event_type = 'Button clicked';

  constructor(
    public event_properties?: ButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConnectHomeqUserError implements BaseEvent {
  event_type = 'Connect homeq user error';

  constructor(
    public event_properties?: ConnectHomeqUserErrorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConnectHomeqUserSuccess implements BaseEvent {
  event_type = 'Connect homeq user success';
}

export class ContactTenantModalOpened implements BaseEvent {
  event_type = 'Contact tenant modal opened';

  constructor(
    public event_properties?: ContactTenantModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContractPartiallySigned implements BaseEvent {
  event_type = 'Contract partially signed';

  constructor(
    public event_properties: ContractPartiallySignedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateTenantAdFromTori implements BaseEvent {
  event_type = 'create tenant ad from tori';
}

export class CreditCheckDenied implements BaseEvent {
  event_type = 'Credit check denied';

  constructor(
    public event_properties?: CreditCheckDeniedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreditCheckError implements BaseEvent {
  event_type = 'Credit check error';
}

export class DigitalViewingOpened implements BaseEvent {
  event_type = 'Digital viewing opened';

  constructor(
    public event_properties: DigitalViewingOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomeqIntroAccountAction implements BaseEvent {
  event_type = 'Homeq intro account action';

  constructor(
    public event_properties: HomeqIntroAccountActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomeqRedirectDialogOpened implements BaseEvent {
  event_type = 'Homeq redirect dialog opened';

  constructor(
    public event_properties?: HomeqRedirectDialogOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomeqRedirectDialogReadMoreClicked implements BaseEvent {
  event_type = 'Homeq redirect dialog read more clicked';

  constructor(
    public event_properties?: HomeqRedirectDialogReadMoreClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HomeqRedirectLinkClicked implements BaseEvent {
  event_type = 'Homeq redirect link clicked';

  constructor(
    public event_properties: HomeqRedirectLinkClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IfInsuranceDialogOpened implements BaseEvent {
  event_type = 'If insurance dialog opened';
}

export class InstantMatchContactTenant implements BaseEvent {
  event_type = 'Instant match contact tenant';

  constructor(
    public event_properties: InstantMatchContactTenantProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InstantMatchTenantListViewed implements BaseEvent {
  event_type = 'Instant match tenant list viewed';

  constructor(
    public event_properties: InstantMatchTenantListViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InstantMatchViewTenantProfile implements BaseEvent {
  event_type = 'Instant match view tenant profile';

  constructor(
    public event_properties: InstantMatchViewTenantProfileProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InstantSignAnotherPersonSignedFirst implements BaseEvent {
  event_type = 'Instant sign another person signed first';

  constructor(
    public event_properties: InstantSignAnotherPersonSignedFirstProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InstantSignFlowStepViewed implements BaseEvent {
  event_type = 'Instant sign flow step viewed';

  constructor(
    public event_properties: InstantSignFlowStepViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InstantSignInitiateContractSigning implements BaseEvent {
  event_type = 'Instant sign initiate contract signing';

  constructor(
    public event_properties: InstantSignInitiateContractSigningProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InstantSignLandlordStartSigning implements BaseEvent {
  event_type = 'Instant sign landlord start signing';

  constructor(
    public event_properties: InstantSignLandlordStartSigningProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InstantSignModalOpened implements BaseEvent {
  event_type = 'Instant sign modal opened';

  constructor(
    public event_properties: InstantSignModalOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InstantSignNotInterested implements BaseEvent {
  event_type = 'Instant sign not interested';

  constructor(
    public event_properties: InstantSignNotInterestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InstantSignStartFlow implements BaseEvent {
  event_type = 'Instant sign start flow';

  constructor(
    public event_properties: InstantSignStartFlowProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IntentfulSearch implements BaseEvent {
  event_type = 'Intentful search';

  constructor(
    public event_properties: IntentfulSearchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LandlordApplicationSent implements BaseEvent {
  event_type = 'Landlord application sent';
}

export class LandlordContractSignLandingPageOpened implements BaseEvent {
  event_type = 'Landlord contract sign landing page opened';

  constructor(
    public event_properties: LandlordContractSignLandingPageOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ListingGalleryImageLoaded implements BaseEvent {
  event_type = 'Listing gallery image loaded';

  constructor(
    public event_properties: ListingGalleryImageLoadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OikotieOtherRentalPropertyClicked implements BaseEvent {
  event_type = 'Oikotie other rental property clicked';

  constructor(
    public event_properties?: OikotieOtherRentalPropertyClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PublishListing implements BaseEvent {
  event_type = 'publish listing';

  constructor(
    public event_properties: PublishListingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PublishTenantAd implements BaseEvent {
  event_type = 'publish tenant ad';
}

export class SendApplication implements BaseEvent {
  event_type = 'send application';

  constructor(
    public event_properties: SendApplicationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendChatMessage implements BaseEvent {
  event_type = 'Send chat message';

  constructor(
    public event_properties: SendChatMessageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShortcutOptIn implements BaseEvent {
  event_type = 'Shortcut opt in';

  constructor(
    public event_properties: ShortcutOptInProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShortcutOptOut implements BaseEvent {
  event_type = 'Shortcut opt out';

  constructor(
    public event_properties: ShortcutOptOutProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignUp implements BaseEvent {
  event_type = 'sign up';
}

export class SimilarHomeOpened implements BaseEvent {
  event_type = 'Similar home opened';

  constructor(
    public event_properties: SimilarHomeOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SuggestedDescriptionRequested implements BaseEvent {
  event_type = 'Suggested description requested';

  constructor(
    public event_properties: SuggestedDescriptionRequestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SupportCallRequested implements BaseEvent {
  event_type = 'Support call requested';

  constructor(
    public event_properties?: SupportCallRequestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TenantContractSignLandingPageOpened implements BaseEvent {
  event_type = 'Tenant contract sign landing page opened';

  constructor(
    public event_properties: TenantContractSignLandingPageOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewHome implements BaseEvent {
  event_type = 'view home';

  constructor(
    public event_properties: ViewHomeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * [Amplitude] Page Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/%5BAmplitude%5D%20Page%20Viewed)
   *
   * Owner: Filip Ström
   *
   * @param properties The event's properties (e.g. [Amplitude] Page Domain)
   * @param options Amplitude event options.
   */
  amplitudePageViewed(
    properties?: AmplitudePageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudePageViewed(properties), options);
  }

  /**
   * About qasa dialog opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/About%20qasa%20dialog%20opened)
   *
   * The About Qasa dialog (visible in qasa_finland in the create listing flow) was opened.
   *
   * @param properties The event's properties (e.g. [Amplitude] Page URL)
   * @param options Amplitude event options.
   */
  aboutQasaDialogOpened(
    properties?: AboutQasaDialogOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AboutQasaDialogOpened(properties), options);
  }

  /**
   * Application Flow Step Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Application%20Flow%20Step%20Viewed)
   *
   * A tenant entered a step in an application flow
   *
   * @param properties The event's properties (e.g. applicationStep)
   * @param options Amplitude event options.
   */
  applicationFlowStepViewed(
    properties: ApplicationFlowStepViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApplicationFlowStepViewed(properties), options);
  }

  /**
   * Application modal opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Application%20modal%20opened)
   *
   * A user opened the application modal by clicking the "Contact" button on a listing
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  applicationModalOpened(
    properties: ApplicationModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApplicationModalOpened(properties), options);
  }

  /**
   * Button clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Button%20clicked)
   *
   * A button was clicked by a user
   *
   * @param properties The event's properties (e.g. [Amplitude] Page URL)
   * @param options Amplitude event options.
   */
  buttonClicked(
    properties?: ButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ButtonClicked(properties), options);
  }

  /**
   * Connect homeq user error
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Connect%20homeq%20user%20error)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. connectHomeqUserAccountError)
   * @param options Amplitude event options.
   */
  connectHomeqUserError(
    properties?: ConnectHomeqUserErrorProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConnectHomeqUserError(properties), options);
  }

  /**
   * Connect homeq user success
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Connect%20homeq%20user%20success)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  connectHomeqUserSuccess(
    options?: EventOptions,
  ) {
    return this.track(new ConnectHomeqUserSuccess(), options);
  }

  /**
   * Contact tenant modal opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Contact%20tenant%20modal%20opened)
   *
   * A user opens the contact tenant modal by clicking the "Contact" button on the profile
   *
   * @param properties The event's properties (e.g. [Amplitude] Page URL)
   * @param options Amplitude event options.
   */
  contactTenantModalOpened(
    properties?: ContactTenantModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContactTenantModalOpened(properties), options);
  }

  /**
   * Contract partially signed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Contract%20partially%20signed)
   *
   * A contract was signed by a party (landlord or tenant). This does not mean that the contract is valid yet.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  contractPartiallySigned(
    properties: ContractPartiallySignedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ContractPartiallySigned(properties), options);
  }

  /**
   * create tenant ad from tori
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/create%20tenant%20ad%20from%20tori)
   *
   * Temporary link in the new introduction card for the Tori traffic A/B test (to track the drop-off from the Qasa-direct funnel due to not wanting to rent out a home at all)
   *
   * @param options Amplitude event options.
   */
  createTenantAdFromTori(
    options?: EventOptions,
  ) {
    return this.track(new CreateTenantAdFromTori(), options);
  }

  /**
   * Credit check denied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Credit%20check%20denied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. isFirsthandHome)
   * @param options Amplitude event options.
   */
  creditCheckDenied(
    properties?: CreditCheckDeniedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreditCheckDenied(properties), options);
  }

  /**
   * Credit check error
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Credit%20check%20error)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  creditCheckError(
    options?: EventOptions,
  ) {
    return this.track(new CreditCheckError(), options);
  }

  /**
   * Digital viewing opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Digital%20viewing%20opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  digitalViewingOpened(
    properties: DigitalViewingOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DigitalViewingOpened(properties), options);
  }

  /**
   * Homeq intro account action
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Homeq%20intro%20account%20action)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeqAccountAction)
   * @param options Amplitude event options.
   */
  homeqIntroAccountAction(
    properties: HomeqIntroAccountActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomeqIntroAccountAction(properties), options);
  }

  /**
   * Homeq redirect dialog opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Homeq%20redirect%20dialog%20opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. [Amplitude] Page Path)
   * @param options Amplitude event options.
   */
  homeqRedirectDialogOpened(
    properties?: HomeqRedirectDialogOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomeqRedirectDialogOpened(properties), options);
  }

  /**
   * Homeq redirect dialog read more clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Homeq%20redirect%20dialog%20read%20more%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. [Amplitude] Page Path)
   * @param options Amplitude event options.
   */
  homeqRedirectDialogReadMoreClicked(
    properties?: HomeqRedirectDialogReadMoreClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomeqRedirectDialogReadMoreClicked(properties), options);
  }

  /**
   * Homeq redirect link clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Homeq%20redirect%20link%20clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. [Amplitude] Page Path)
   * @param options Amplitude event options.
   */
  homeqRedirectLinkClicked(
    properties: HomeqRedirectLinkClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new HomeqRedirectLinkClicked(properties), options);
  }

  /**
   * If insurance dialog opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/If%20insurance%20dialog%20opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  ifInsuranceDialogOpened(
    options?: EventOptions,
  ) {
    return this.track(new IfInsuranceDialogOpened(), options);
  }

  /**
   * Instant match contact tenant
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Instant%20match%20contact%20tenant)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  instantMatchContactTenant(
    properties: InstantMatchContactTenantProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstantMatchContactTenant(properties), options);
  }

  /**
   * Instant match tenant list viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Instant%20match%20tenant%20list%20viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  instantMatchTenantListViewed(
    properties: InstantMatchTenantListViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstantMatchTenantListViewed(properties), options);
  }

  /**
   * Instant match view tenant profile
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Instant%20match%20view%20tenant%20profile)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  instantMatchViewTenantProfile(
    properties: InstantMatchViewTenantProfileProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstantMatchViewTenantProfile(properties), options);
  }

  /**
   * Instant sign another person signed first
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Instant%20sign%20another%20person%20signed%20first)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  instantSignAnotherPersonSignedFirst(
    properties: InstantSignAnotherPersonSignedFirstProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstantSignAnotherPersonSignedFirst(properties), options);
  }

  /**
   * Instant sign flow step viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Instant%20sign%20flow%20step%20viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  instantSignFlowStepViewed(
    properties: InstantSignFlowStepViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstantSignFlowStepViewed(properties), options);
  }

  /**
   * Instant sign initiate contract signing
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Instant%20sign%20initiate%20contract%20signing)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  instantSignInitiateContractSigning(
    properties: InstantSignInitiateContractSigningProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstantSignInitiateContractSigning(properties), options);
  }

  /**
   * Instant sign landlord start signing
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Instant%20sign%20landlord%20start%20signing)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  instantSignLandlordStartSigning(
    properties: InstantSignLandlordStartSigningProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstantSignLandlordStartSigning(properties), options);
  }

  /**
   * Instant sign modal opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Instant%20sign%20modal%20opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  instantSignModalOpened(
    properties: InstantSignModalOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstantSignModalOpened(properties), options);
  }

  /**
   * Instant sign not interested
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Instant%20sign%20not%20interested)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  instantSignNotInterested(
    properties: InstantSignNotInterestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstantSignNotInterested(properties), options);
  }

  /**
   * Instant sign start flow
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Instant%20sign%20start%20flow)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  instantSignStartFlow(
    properties: InstantSignStartFlowProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstantSignStartFlow(properties), options);
  }

  /**
   * Intentful search
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Intentful%20search)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeIds)
   * @param options Amplitude event options.
   */
  intentfulSearch(
    properties: IntentfulSearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new IntentfulSearch(properties), options);
  }

  /**
   * Landlord application sent
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Landlord%20application%20sent)
   *
   * A landlord sends an application to a tenant
   *
   * @param options Amplitude event options.
   */
  landlordApplicationSent(
    options?: EventOptions,
  ) {
    return this.track(new LandlordApplicationSent(), options);
  }

  /**
   * Landlord contract sign landing page opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Landlord%20contract%20sign%20landing%20page%20opened)
   *
   * This page is used to introduce the LL to the contract they're about to sign. For now used only in instant sign signing flow.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  landlordContractSignLandingPageOpened(
    properties: LandlordContractSignLandingPageOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LandlordContractSignLandingPageOpened(properties), options);
  }

  /**
   * Listing gallery image loaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Listing%20gallery%20image%20loaded)
   *
   * This event tracks the time it takes for each image in the listing gallery listing to laod
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  listingGalleryImageLoaded(
    properties: ListingGalleryImageLoadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ListingGalleryImageLoaded(properties), options);
  }

  /**
   * Oikotie other rental property clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Oikotie%20other%20rental%20property%20clicked)
   *
   * User clicked a disclaimer indicating that they want to rent out something else than a home.
   *
   * @param properties The event's properties (e.g. [Amplitude] Page URL)
   * @param options Amplitude event options.
   */
  oikotieOtherRentalPropertyClicked(
    properties?: OikotieOtherRentalPropertyClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OikotieOtherRentalPropertyClicked(properties), options);
  }

  /**
   * publish listing
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/publish%20listing)
   *
   * A listing was published by a landlord. This event triggers when the landlord sends the listing for automatic review, so it may still be denied on rare occasions.
   *
   * Owner: Filip Ström
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  publishListing(
    properties: PublishListingProperties,
    options?: EventOptions,
  ) {
    return this.track(new PublishListing(properties), options);
  }

  /**
   * publish tenant ad
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/publish%20tenant%20ad)
   *
   * A tenant published an ad to be shown in find tenant
   *
   * @param options Amplitude event options.
   */
  publishTenantAd(
    options?: EventOptions,
  ) {
    return this.track(new PublishTenantAd(), options);
  }

  /**
   * send application
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/send%20application)
   *
   * A tenant sent an application to a home
   *
   * Owner: Filip Ström
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  sendApplication(
    properties: SendApplicationProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendApplication(properties), options);
  }

  /**
   * Send chat message
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Send%20chat%20message)
   *
   * A user sent a chat message. System messages do not count as chat messages.
   *
   * @param properties The event's properties (e.g. conversationId)
   * @param options Amplitude event options.
   */
  sendChatMessage(
    properties: SendChatMessageProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendChatMessage(properties), options);
  }

  /**
   * Shortcut opt in
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Shortcut%20opt%20in)
   *
   * **This event tracks when a user opts in to use Shortcut when creating a Home that is eligible for the service**
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  shortcutOptIn(
    properties: ShortcutOptInProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShortcutOptIn(properties), options);
  }

  /**
   * Shortcut opt out
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Shortcut%20opt%20out)
   *
   * **This event tracks when a user opts out of Shortcut when creating a Home that is eligible for the service**
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  shortcutOptOut(
    properties: ShortcutOptOutProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShortcutOptOut(properties), options);
  }

  /**
   * sign up
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/sign%20up)
   *
   * A user created an account
   *
   * Owner: Filip Ström
   *
   * @param options Amplitude event options.
   */
  signUp(
    options?: EventOptions,
  ) {
    return this.track(new SignUp(), options);
  }

  /**
   * Similar home opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Similar%20home%20opened)
   *
   * User has clicked on a home showed on the similar homes carousel
   *
   * @param properties The event's properties (e.g. [Amplitude] Page URL)
   * @param options Amplitude event options.
   */
  similarHomeOpened(
    properties: SimilarHomeOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SimilarHomeOpened(properties), options);
  }

  /**
   * Suggested description requested
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Suggested%20description%20requested)
   *
   * This event is triggered when a user requests a suggested description for a listing
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  suggestedDescriptionRequested(
    properties: SuggestedDescriptionRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SuggestedDescriptionRequested(properties), options);
  }

  /**
   * Support call requested
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Support%20call%20requested)
   *
   * This event is triggered when a user requests a support call. A support call can be requested at a few different views within the product. We want to make sure we know from which view the request was made.
   *
   * @param properties The event's properties (e.g. [Amplitude] Page URL)
   * @param options Amplitude event options.
   */
  supportCallRequested(
    properties?: SupportCallRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SupportCallRequested(properties), options);
  }

  /**
   * Tenant contract sign landing page opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/Tenant%20contract%20sign%20landing%20page%20opened)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  tenantContractSignLandingPageOpened(
    properties: TenantContractSignLandingPageOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TenantContractSignLandingPageOpened(properties), options);
  }

  /**
   * view home
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/schibstedmedia/Qasa%20staging/events/main/latest/view%20home)
   *
   * A user (landlord or tenant) viewed a home by visiting its listing page.
   *
   * @param properties The event's properties (e.g. homeId)
   * @param options Amplitude event options.
   */
  viewHome(
    properties: ViewHomeProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewHome(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
